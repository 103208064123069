import React, { useState } from "react";
import {
  Button as MaterialButton,
  FormControl,
  FormLabel,
  Paper,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@material-ui/core";
import ImagePicker from "react-image-picker";
import { useNode } from "@craftjs/core";
import { ColorPicker, useColor } from "react-color-palette";
import axios from "axios";
import "react-color-palette/lib/css/styles.css";

export const Image = ({
  background,
  padding,
  minHeight,
  width,
  height,
  children,
  imageSrc,
  clickEvent,
  ...props
}) => {
  const {
    connectors: { connect, drag },
  } = useNode();
  return (
    <Paper
      {...props}
      ref={(ref) => connect(drag(ref))}
      style={{
        background: `${background}`,
        backgroundImage: `url(${imageSrc || ""}`,
        padding: `${padding}px`,
        width: `${width}px`,
        height: `${height}px`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      {children}
    </Paper>
  );
};

export const ImageSettings = () => {
  let [imageList, setImageList] = useState();

  const url = process.env.REACT_APP_API_URL + "s3-upload-file";
  const [dialogOpen, setDialogOpen] = useState(false);
  const [colorpicker, setColor] = useColor("hex", "#ffffff");
  const handleColor = (event) => {
    setProp((prop) => (prop.background = event.hex));

    setColor(event);
  };
  const [filename, setFileName] = useState();

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileInput = (event) => {
    console.log(event.target.files[0]);
    setSelectedFile(event.target.files[0]);

    setFileName(event.target.files[0].name);
    console.log("filename:" + filename);
    console.log("selectedFile:" + selectedFile);
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setSelectedFile(e.target.result);
    };
  };

  const handleOnPick = (event) => {
    console.log("on pick image");
    console.log(event);
    setProp((prop) => (prop.imageSrc = event.src));
    setDialogOpen(false);
  };
  const handleUpload = () => {
    if (selectedFile === null || selectedFile === "") {
      alert("Please Select an image first");
    }
    console.log("FILENAME:" + filename);
    const formData = { name: filename, image: selectedFile };

    console.log("formdata:" + formData);
    axios.post(url, formData).then((res) => {
      setProp((prop) => (prop.imageSrc = res.data.data));
      // then print response status
      console.warn(res.data.data);
    });
  };
  const handleAwsPhotos = () => {
    if (imageList) {
      setDialogOpen(true);
    } else {
      axios.get(url).then((res) => {
        console.warn(res.data);
        console.log(res.data.data);
        setImageList(res.data.data);
        setDialogOpen(true);
      });
    }
  };
  const {
    padding,
    width,
    height,
    imageSrc,
    clickEvent,
    actions: { setProp },
  } = useNode((node) => ({
    background: node.data.props.background,
    padding: node.data.props.padding,
    width: node.data.props.width,
    height: node.data.props.height,
    imageSrc: node.data.props.imageSrc,
    clickEvent: node.data.props.clickEvent,
  }));

  return (
    <div>
      <FormControl margin="normal" component="fieldset">
        <FormLabel component="legend">Image URL</FormLabel>
        <TextField
          type={"url"}
          value={imageSrc}
          placeholder="Image URL"
          onChange={(e) => setProp((prop) => (prop.imageSrc = e.target.value))}
        />
      </FormControl>
      <br />
      <FormControl margin="normal" component="fieldset">
        <TextField
          type={"file"}
          className={"upload-text"}
          inputProps={{ accept: "image/jpeg,image/png,image/jpg,image/gif" }}
          placeholder="Image URL"
          onChange={handleFileInput}
        />
      </FormControl>
      <br />
      <FormControl margin="normal" component="fieldset">
        <MaterialButton
          placeholder="Upload"
          color="secondary"
          onClick={handleUpload}
        >
          Upload
        </MaterialButton>
      </FormControl>
      <br />
      <br />
      <FormControl margin="normal" component="fieldset">
        <MaterialButton
          placeholder="Select photos"
          color="secondary"
          onClick={handleAwsPhotos}
        >
          Select uploaded photo
        </MaterialButton>
      </FormControl>
      <br />
      <FormControl margin="normal" component="fieldset">
        <FormLabel component="legend">Click Event</FormLabel>
        <TextField
          type={"url"}
          value={clickEvent}
          placeholder="Click Event"
          onChange={(e) =>
            setProp((prop) => (prop.clickEvent = e.target.value))
          }
        />
      </FormControl>
      <br />
      <FormControl margin="normal" component="fieldset">
        <FormLabel component="legend">Padding</FormLabel>
        <TextField
          type="number"
          InputProps={{ inputProps: { min: 0, max: 200 } }}
          value={padding}
          max={200}
          min={1}
          placeholder="Padding"
          onChange={(e) => setProp((prop) => (prop.padding = e.target.value))}
        />
      </FormControl>
      <br />
      <FormControl margin="normal" component="fieldset">
        <FormLabel component="legend">Dimensions</FormLabel>
        <div>
          <TextField
            type="number"
            InputProps={{ inputProps: { min: 50, max: 500 } }}
            label="Width"
            value={width}
            max={1000}
            min={50}
            placeholder="Width"
            onChange={(e) => setProp((prop) => (prop.width = e.target.value))}
          />
          <TextField
            type="number"
            InputProps={{ inputProps: { min: 50, max: 500 } }}
            value={height}
            label="Height"
            max={1000}
            min={50}
            placeholder="Height"
            onChange={(e) => setProp((prop) => (prop.height = e.target.value))}
          />
        </div>
      </FormControl>
      <br />
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <FormLabel component="legend">Background</FormLabel>
        <ColorPicker
          width={250}
          height={228}
          color={colorpicker}
          hideHSV
          onChange={handleColor}
          onChangeComplete={handleColor}
        />
      </FormControl>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">Load state</DialogTitle>
        <DialogContent>
          <div>
            <ImagePicker
              images={
                imageList &&
                imageList.map((image, i) => ({ src: image, value: i }))
              }
              onPick={handleOnPick}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <MaterialButton onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </MaterialButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const ImageDefaultProps = {
  background: "#ffffff",
  width: 450,
  height: 250,
};

Image.craft = {
  props: ImageDefaultProps,
  related: {
    settings: ImageSettings,
  },
};
