import { useEditor, useNode } from "@craftjs/core";
import {
  Button as MaterialButton,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Paper, FormControl, FormLabel } from "@material-ui/core";
import React from "react";
import { Text } from "./Text";
import { ColorPicker, useColor } from "react-color-palette";

export const Ad = ({
  background,
  width,
  height,
  adtype,
  children,
  ...props
}) => {
  const {
    connectors: { connect, drag },
  } = useNode();
  return (
    <Paper
      {...props}
      ref={(ref) => connect(drag(ref))}
      style={{
        margin: "5px 0",
        background,
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      {children}
    </Paper>
  );
};

export const AdSettings = () => {
  const [colorpicker, setColor] = useColor("hex", "#ffffff");
  const handleColor = (event) => {
    setProp((prop) => (prop.background = event.hex));

    setColor(event);
  };
  const {
    background,
    width,
    height,
    adtype,
    actions: { setProp },
  } = useNode((node) => ({
    background: node.data.props.background,
    width: node.data.props.width,
    height: node.data.props.height,
    adtype: node.data.props.adtype,
  }));

  const { connectors } = useEditor();

  const adTypeOptions = [
    { value: "interstitial", label: "Interstitial" },
    { value: "native", label: "Native" },
    { value: "mrec", label: "Mrec" },
    { value: "banner", label: "Banner" },
  ];

  return (
    <div>
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <MaterialButton
          ref={(ref) => connectors.create(ref, <Text text="Placement" />)}
          variant="contained"
          data-cy="toolbox-text"
          style={{ marginBottom: "20px" }}
        >
          Placement
        </MaterialButton>
        <FormLabel component="legend">Background</FormLabel>
        <ColorPicker
          width={250}
          height={228}
          color={colorpicker}
          onChange={handleColor}
          onChangeComplete={handleColor}
        />
      </FormControl>
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <FormLabel component="legend">Width</FormLabel>
        <TextField
          value={width}
          type={"number"}
          onChange={(event) =>
            setProp((props) => (props.width = event.target.value), 500)
          }
        />
      </FormControl>
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <FormLabel component="legend">Height</FormLabel>
        <TextField
          value={height}
          type={"number"}
          onChange={(event) =>
            setProp((props) => (props.height = event.target.value), 500)
          }
        />
      </FormControl>
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <FormLabel component="legend">Ad Type</FormLabel>
        <Select
          value={adtype}
          onChange={(event) =>
            setProp((props) => (props.adtype = event.target.value), 500)
          }
        >
          {adTypeOptions.map(({ value, label }, index) => (
            <MenuItem value={value}>{label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export const ContainerDefaultProps = {
  background: "#ffffff",
  padding: 3,
};

Ad.craft = {
  props: ContainerDefaultProps,
  related: {
    settings: AdSettings,
  },
};
