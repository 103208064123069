import { useNode } from "@craftjs/core";
import {
  FormControl,
  FormLabel,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ContentEditable from "react-contenteditable";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

export const Text = ({
  text,
  fontSize,
  width,
  height,
  color,
  textAlign,
  padding,
  bold,
  italic,
  underlined,
  clickEvent,
  ...props
}) => {
  const {
    connectors: { connect, drag },
    selected,
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));

  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (selected) {
      return;
    }

    setEditable(false);
  }, [selected]);

  return (
    <div
      {...props}
      ref={(ref) => connect(drag(ref))}
      onClick={() => selected && setEditable(true)}
    >
      <ContentEditable
        html={text}
        disabled={!editable}
        onChange={(e) =>
          setProp(
            (props) =>
              (props.text = e.target.value.replace(/<\/?[^>]+(>|$)/g, "")),
            500
          )
        }
        tagName="p"
        style={{
          fontSize: `${fontSize}px`,
          width: `${width}px`,
          height: `${height}px`,
          color: `${color}`,
          textAlign: `${textAlign}`,
          padding: `${padding}px`,
          fontWeight: `${bold}`,
          fontStyle: `${italic}`,
          textDecoration: `${underlined}`,
        }}
      />
    </div>
  );
};

const TextSettings = () => {
  const [colorpicker, setColor] = useColor("hex", "#000000");
  const [formats, setFormats] = React.useState();
  const handleFormat = (event, newFormats) => {
    const bold = newFormats.some((v) => v === "bold");
    const italic = newFormats.some((v) => v === "italic");
    const underlined = newFormats.some((v) => v === "underlined");
    setProp((prop) => (prop.bold = bold ? "bold" : ""));
    setProp((prop) => (prop.italic = italic ? "italic" : ""));
    setProp((prop) => (prop.underlined = underlined ? "underline" : ""));

    setFormats(newFormats);
  };
  const handleColor = (event) => {
    setProp((prop) => (prop.color = event.hex));

    setColor(event);
  };
  const {
    actions: { setProp },
    fontSize,
    width,
    height,
    color,
    textAlign,
    padding,
    bold,
    italic,
    underlined,
    clickEvent,
  } = useNode((node) => ({
    text: node.data.props.text,
    fontSize: node.data.props.fontSize,
    width: node.data.props.width,
    height: node.data.props.height,
    textAlign: node.data.props.textAlign,
    clickEvent: node.data.props.clickEvent,
  }));

  return (
    <div>
      <FormControl margin="normal" component="fieldset">
        <FormLabel component="legend">Click Event</FormLabel>
        <TextField
          type={"url"}
          value={clickEvent}
          placeholder="Click Event"
          onChange={(e) =>
            setProp((prop) => (prop.clickEvent = e.target.value))
          }
        />
      </FormControl>
      <br />
      <FormControl size="small" component="fieldset">
        <div>
          <FormLabel component="legend">Font size</FormLabel>
          <TextField
            type="number"
            InputProps={{ inputProps: { min: 5, max: 500 } }}
            value={fontSize}
            max={50}
            min={5}
            placeholder="Font size"
            onChange={(e) =>
              setProp((prop) => (prop.fontSize = e.target.value))
            }
          />
        </div>
        <br />
        <div>
          <TextField
            type="number"
            InputProps={{ inputProps: { min: 10, max: 500 } }}
            label="Width"
            value={width}
            max={500}
            min={10}
            placeholder="Width"
            onChange={(e) => setProp((prop) => (prop.width = e.target.value))}
          />
          <TextField
            type="number"
            InputProps={{ inputProps: { min: 20, max: 500 } }}
            value={height}
            label="Height"
            max={500}
            min={20}
            placeholder="Height"
            onChange={(e) => setProp((prop) => (prop.height = e.target.value))}
          />
        </div>
        <br />

        <TextField
          type="number"
          InputProps={{ inputProps: { min: 0, max: 200 } }}
          value={padding}
          label="Padding"
          max={200}
          min={0}
          placeholder="Padding"
          onChange={(e) => setProp((prop) => (prop.padding = e.target.value))}
        />
        <br />
        <div>
          <ToggleButtonGroup
            value={formats}
            onChange={handleFormat}
            aria-label="text formatting"
          >
            <ToggleButton value="bold" aria-label="bold">
              <FormatBoldIcon />
            </ToggleButton>
            <ToggleButton value="italic" aria-label="italic">
              <FormatItalicIcon />
            </ToggleButton>
            <ToggleButton value="underlined" aria-label="underlined">
              <FormatUnderlinedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <br />
        <FormControl component="fieldset">
          <FormLabel component="legend">Text Align</FormLabel>
          <RadioGroup
            defaultValue={textAlign}
            onChange={(e) =>
              setProp((prop) => (prop.textAlign = e.target.value))
            }
          >
            <FormControlLabel
              label="Left"
              value="left"
              control={<Radio size="small" color="primary" />}
            />
            <FormControlLabel
              label="Center"
              value="center"
              control={<Radio size="small" color="primary" />}
            />
            <FormControlLabel
              label="Right"
              value="right"
              control={<Radio size="small" color="primary" />}
            />
          </RadioGroup>
        </FormControl>
        <br />

        <FormLabel component="legend">Text Color</FormLabel>

        <ColorPicker
          width={250}
          height={228}
          color={colorpicker}
          hideHSV
          onChange={handleColor}
          onChangeComplete={handleColor}
        />
      </FormControl>
      <br />
    </div>
  );
};

export const TextDefaultProps = {
  text: "Hi",
  fontSize: 20,
  width: 100,
  height: 50,
  color: `#000000`,
  textAlign: "left",
};

Text.craft = {
  props: TextDefaultProps,
  related: {
    settings: TextSettings,
  },
};
