import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap";
import './main.scss';
import App from './App';
import { Editor } from "@craftjs/core";
import { Button } from "./components/user/Button";
import { Card, CardBottom, CardTop } from "./components/user/Card";
import { Container } from "./components/user/Container";
import { Text } from "./components/user/Text";
import {Ad} from "./components/user/Ad";
import { Image } from "./components/user/Image";

ReactDOM.render( (<Editor

    resolver={{
        Card,
        Button,
        Text,
        Container,
        CardTop,
        CardBottom,
        Ad,
        Image,
      }}

><App /></Editor>),document.getElementById("root"));

