import { useNode } from "@craftjs/core";
import { Slider, TextField } from "@material-ui/core";
import { Paper, FormControl, FormLabel } from "@material-ui/core";
import React from "react";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";

export const Container = ({
  background,
  padding,
  minHeight,
  width,
  height,
  children,
  imageSrc,
  clickEvent,
  ...props
}) => {
  const {
    connectors: { connect, drag },
  } = useNode();
  return (
    <Paper
      {...props}
      ref={(ref) => connect(drag(ref))}
      style={{
        margin: "5px 0",
        background,
        padding: `${padding}px`,
        width: `${width}px`,
        height: `${height}px`,
        backgroundImage: `url(${imageSrc || ""}`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      {children}
    </Paper>
  );
};

export const ContainerSettings = () => {
  const [colorpicker, setColor] = useColor("hex", "#ffffff");
  const handleColor = (event) => {
    setProp((prop) => (prop.background = event.hex));

    setColor(event);
  };
  const {
    background,
    padding,
    width,
    height,
    imageSrc,
    clickEvent,
    actions: { setProp },
  } = useNode((node) => ({
    background: node.data.props.background,
    padding: node.data.props.padding,
    width: node.data.props.width,
    height: node.data.props.height,
    imageSrc: node.data.props.imageSrc,
    clickEvent: node.data.props.clickEvent,
  }));

  return (
    <div>
      <FormControl margin="normal" component="fieldset">
        <FormLabel component="legend">Click Event</FormLabel>
        <TextField
          type={"url"}
          value={clickEvent}
          placeholder="Click Event"
          onChange={(e) =>
            setProp((prop) => (prop.clickEvent = e.target.value))
          }
        />
      </FormControl>
      <br />
      <FormControl margin="normal" component="fieldset">
        <FormLabel component="legend">Padding</FormLabel>
        <TextField
          type="number"
          InputProps={{ inputProps: { min: 0, max: 200 } }}
          value={padding}
          max={200}
          min={1}
          placeholder="Padding"
          onChange={(e) => setProp((prop) => (prop.padding = e.target.value))}
        />
      </FormControl>
      <br />
      <FormControl margin="normal" component="fieldset">
        <FormLabel component="legend">Dimensions</FormLabel>
        <div>
          <TextField
            type="number"
            InputProps={{ inputProps: { min: 50, max: 500 } }}
            label="Width"
            value={width}
            max={1000}
            min={50}
            placeholder="Width"
            onChange={(e) => setProp((prop) => (prop.width = e.target.value))}
          />
          <TextField
            type="number"
            InputProps={{ inputProps: { min: 50, max: 500 } }}
            value={height}
            label="Height"
            max={1000}
            min={50}
            placeholder="Height"
            onChange={(e) => setProp((prop) => (prop.height = e.target.value))}
          />
        </div>
      </FormControl>
      <br />
      <FormControl margin="normal" component="fieldset">
        <FormLabel component="legend">Background Image URL</FormLabel>
        <TextField
          type={"url"}
          value={imageSrc}
          placeholder="Background Image"
          onChange={(e) => setProp((prop) => (prop.imageSrc = e.target.value))}
        />
      </FormControl>
      <br />
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <FormLabel component="legend">Background</FormLabel>
        <ColorPicker
          width={250}
          height={228}
          color={colorpicker}
          onChange={handleColor}
          onChangeComplete={handleColor}
        />
      </FormControl>
    </div>
  );
};

export const ContainerDefaultProps = {
  background: "#ffffff",
  padding: 3,
  width: 450,
  height: 100,
};

Container.craft = {
  props: ContainerDefaultProps,
  related: {
    settings: ContainerSettings,
  },
};
